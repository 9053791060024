import React from "react";
import img01 from "./img/01/01.png";
import img02 from "./img/02/02.png";
import img03 from "./img/03/03.png";
import img04 from "./img/04/04.png";
import img05 from "./img/05/05.png";
import img06 from "./img/06/06.png";
import img07 from "./img/07/07.png";

import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../../customHooks/useWindowDimention";

function ReadMore() {
  const [t] = useTranslation();
  const { width } = useWindowDimensions();

  if (width >= 1024) {
    return (
      <div className="read-more read-more-desktop">
        <div className="container-lg">
          <div className="read-flex">
            <div className="read-flex__left">
              <div className="read-img">
                <img src={img01} alt="" />
              </div>
              <div className="read-img">
                <img src={img02} alt="" />
              </div>
              <div className="read-img">
                <img src={img03} alt="" />
              </div>
              <div className="read-img">
                <img src={img04} alt="" />
              </div>
              <div className="read-img">
                <img src={img05} alt="" />
              </div>
              <div className="read-img">
                <img src={img07} alt="" />
              </div>
            </div>
            <div className="read-flex__right top-text">
              <div className="section-title top-text__title">
                {t("applyingTitle")}
              </div>
              <div className="read-more__text">
                <p>{t("applyingDescriptionText1")}</p>
                <p>{t("applyingDescriptionText2")}</p>
                <p>{t("applyingDescriptionText3")}</p>
                <p>
                  <span className="disclaimer">{t("Disclaimer*")}</span>
                  {t("disclaimerText")}
                </p>
              </div>
              <div className="read-border">
                <div className="read-border-item">
                  <div className="read-circle">1</div>
                  <div className="read-border__title">{t("guide1Title")}</div>
                  <div className="read-border__text">{t("guide1Text")}</div>
                </div>

                <div className="read-border-item">
                  <div className="read-circle">2</div>
                  <div className="read-border__title">{t("guide2Title")}</div>
                  <div className="read-border__text">
                    <p>{t("guide2TextP1")}</p>
                    <p>{t("guide2TextP2")}</p>
                    <ul>
                      <li>{t("guide2ListItem1")}</li>
                      <li>{t("guide2ListItem2")}</li>
                      <li>{t("guide2ListItem3")}</li>
                      <li>{t("guide2ListItem4")}</li>
                      <li>{t("guide2ListItem5")}</li>
                    </ul>
                    <p>{t("guide2TextP3")}</p>
                    <p>{t("guide2TextP4")}</p>
                  </div>
                </div>

                <div className="read-border-item">
                  <div className="read-circle">3</div>
                  <div className="read-border__title">{t("guide3Title")}</div>
                  <div className="read-border__text">{t("guide3Text")}</div>
                </div>

                <div className="read-border-item">
                  <div className="read-circle">4</div>
                  <div className="read-border__title">{t("guide4Title")}</div>
                  <div className="read-border__text">
                    <p>{t("guide4TextP1")}</p>
                    <p>{t("guide4TextP2")}</p>
                  </div>
                </div>

                <div className="read-border-item">
                  <div className="read-circle">5</div>
                  <div className="read-border__title">{t("guide5Title")}</div>
                  <div className="read-border__text">
                    <p>{t("guide5TextP1")}</p>
                    <p>{t("guide5TextP2")}</p>
                    <p>{t("guide5TextP3")}</p>
                    <p>{t("guide5TextP4")}</p>
                  </div>
                </div>

                <div className="read-border-item">
                  <div className="read-circle">6</div>
                  <div className="read-border__title">{t("guide6Title")}</div>
                  <div className="read-border__text">
                    <p>{t("guide6TextP1")}</p>
                    <p>{t("guide6TextP2")}</p>
                  </div>
                </div>
              </div>
              <div className="read-border-item ">
                <div className="read-circle">7</div>
                <div className="read-border__title">{t("guide7Title")}</div>
                <div className="read-border__text">
                  <p>{t("guide7Textp1")}</p>
                  <p>{t("guide7Textp2")}</p>
                  <p>
                    {t("guide7TextP3")}
                    <span className="email">
                      <a href="mailto:info@cloudsidetech.com?subject=Testing out mailto!">
                        &nbsp;{t("Email")}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="read-more read-more-mobile">
        <div className="container">
          <div className="top-text">
            <div className="section-title top-text__title">
              {t("applyingTitle")}
            </div>
            <div className="read-more__text">
              <p>{t("applyingDescriptionText1")}</p>
              <p>{t("applyingDescriptionText2")}</p>
              <p>{t("applyingDescriptionText3")}</p>
              <p>
                <span className="disclaimer">{t("Disclaimer*")}</span>
                {t("disclaimerText")}
              </p>
            </div>
          </div>

          <div className="read-more-item">
            <div className="read-more-flex">
              <div className="read-circle">1</div>
              <div className="read-more-flex__img">
                <img src={img02} alt="" />
              </div>
              <img src="" alt="" />
            </div>
            <div className="read-more-item__title">{t("guide1Title")}</div>
            <div className="read-more-item__text">
              <p>{t("guide1Text")}</p>
            </div>
          </div>

          <div className="read-more-item">
            <div className="read-more-flex">
              <div className="read-circle">2</div>
              <div className="read-more-flex__img">
                <img src={img01} alt="" />
              </div>
              <img src="" alt="" />
            </div>
            <div className="read-more-item__title">{t("guide2Title")}</div>
            <div className="read-more-item__text">
              <p>{t("guide2TextP1")}</p>
              <p>{t("guide2TextP2")}</p>
              <ul>
                <li>{t("guide2ListItem1")}</li>
                <li>{t("guide2ListItem2")}</li>
                <li>{t("guide2ListItem3")}</li>
                <li>{t("guide2ListItem4")}</li>
                <li>{t("guide2ListItem5")}</li>
              </ul>
              <p>{t("guide2TextP3")}</p>
              <p>{t("guide2TextP4")}</p>
            </div>
          </div>

          <div className="read-more-item">
            <div className="read-more-flex">
              <div className="read-circle">3</div>
              <div className="read-more-flex__img">
                <img src={img03} alt="" />
              </div>
              <img src="" alt="" />
            </div>
            <div className="read-more-item__title">{t("guide3Title")}</div>
            <div className="read-more-item__text">
              <div className="read-border__text">
                <p>{t("guide3Text")}</p>
              </div>
            </div>
          </div>

          <div className="read-more-item">
            <div className="read-more-flex">
              <div className="read-circle">4</div>
              <div className="read-more-flex__img">
                <img src={img05} alt="" />
              </div>
              <img src="" alt="" />
            </div>
            <div className="read-more-item__title">{t("guide4Title")}</div>
            <div className="read-more-item__text">
              <p>{t("guide4TextP1")}</p>
              <p>{t("guide4TextP2")}</p>
            </div>
          </div>

          <div className="read-more-item">
            <div className="read-more-flex">
              <div className="read-circle">5</div>
              <div className="read-more-flex__img">
                <img src={img04} alt="" />
              </div>
              <img src="" alt="" />
            </div>
            <div className="read-more-item__title">{t("guide5Title")}</div>
            <div className="read-more-item__text">
              <p>{t("guide5TextP1")}</p>
              <p>{t("guide5TextP2")}</p>
              <p>{t("guide5TextP3")}</p>
              <p>{t("guide5TextP4")}</p>
            </div>
          </div>

          <div className="read-more-item">
            <div className="read-more-flex">
              <div className="read-circle">6</div>
              <div className="read-more-flex__img">
                <img src={img06} alt="" />
              </div>
              <img src="" alt="" />
            </div>
            <div className="read-more-item__title">{t("guide6Title")}</div>
            <div className="read-more-item__text">
              <p>{t("guide6TextP1")}</p>
              <p>{t("guide6TextP2")}</p>
            </div>
          </div>

          <div className="read-more-item">
            <div className="read-more-flex">
              <div className="read-circle">7</div>
              <div className="read-more-flex__img">
                <img src={img07} alt="" />
              </div>
              <img src="" alt="" />
            </div>
            <div className="read-more-item__title">{t("guide7Title")}</div>
            <div className="read-more-item__text">
              <p>{t("guide7Textp1")}</p>
              <p>{t("guide7Textp2")}</p>
              <p className="last-p">
                {t("guide7TextP3")}
                <span className="email">
                  <a href="mailto:info@cloudsidetech.com">&nbsp;{t("Email")}</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReadMore;
