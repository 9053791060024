import React from "react";
import badge1 from "./img/01.svg";
import badge2 from "./img/02.svg";
import badge3 from "./img/03.svg";
import { useTranslation } from "react-i18next";

function OurTeam() {
  const [t] = useTranslation();

  return (
    <div className="our-team">
      <div className="our-team-container container-lg"></div>
      <div className="our-team-content">
        <div className="our-team-badges">
          <div className="our-team__badge">
            <img src={badge1} alt="" />
          </div>
          <div className="our-team__badge">
            <img src={badge2} alt="" />
          </div>
          <div className="our-team__badge">
            <img src={badge3} alt="" />
          </div>
          <div className="our-team__bg"></div>
        </div>
        <div className="our-team-text">
          <h3 className="section-title our-team__title">{t("ourTeamTitle")}</h3>
          <p>{t("ourTeamText")}</p>
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
