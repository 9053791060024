import React from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../../customHooks/useWindowDimention";

function HexagonSection() {
  const [t] = useTranslation();

  return (
    <div className="hex-section-wrapper">
      <div className="hexagons-section">
        {/* background hexagons */}
        <div className="background-hex bg-hex-0">
          <div className="background-hex-back">
            <div className="background-hex-front"></div>
          </div>
        </div>
        <div className="background-hex bg-hex-1">
          <div className="background-hex-back">
            <div className="background-hex-front"></div>
          </div>
        </div>
        <div className="background-hex bg-hex-2">
          <div className="background-hex-back">
            <div className="background-hex-front"></div>
          </div>
        </div>
        <div className="background-hex bg-hex-3">
          <div className="background-hex-back">
            <div className="background-hex-front"></div>
          </div>
        </div>
        <div className="background-hex bg-hex-4">
          <div className="background-hex-back">
            <div className="background-hex-front"></div>
          </div>
        </div>

        {/* main hexagons */}

        <div className="hex-col-1">
          <div className="hex-shadow hex-container-1">
            <div className="hex">
              <div className="hex__icon">
                <i className="_icon-heart"></i>
              </div>
              <div className="hex__title">{t("whatWeLiveTitle")}</div>
              <div className="hex__text"></div>
            </div>
          </div>
        </div>
        <div className="hex-col-2">
          <div className="hex-shadow  hex-container-3">
            <div className="hex">
              <div className="hex__icon">
                <i className="_icon-brain"></i>
              </div>
              <div className="hex__title">{t("whatWeHeader1")}</div>
              <div className="hex__text">{t("whatWeText1")}</div>
            </div>
          </div>
          <div className="hex-shadow hex-container-4">
            <div className="hex">
              <div className="hex__icon">
                <i className="_icon-wifi"></i>
              </div>
              <div className="hex__title">{t("whatWeHeader2")}</div>
              <div className="hex__text">{t("whatWeText2")}</div>
            </div>
          </div>
        </div>
        <div className="hex-col-3">
          <div className="hex-shadow hex-container-2">
            <div className="hex">
              <div className="hex__icon">
                <i className="_icon-trophy"></i>
              </div>
              <div className="hex__title">{t("whatWeHeader3")}</div>
              <div className="hex__text">{t("whatWeText3")}</div>
            </div>
          </div>
          <div className="hex-shadow  hex-container-7">
            <div className="hex">
              <div className="hex__icon">
                <span className="_icon-user-check"></span>
              </div>
              <div className="hex__title">{t("whatWeHeader4")}</div>
              <div className="hex__text">{t("whatWeText4")}</div>
              <div className="hex__subtext">{t("whatWeSubText")}</div>
            </div>
          </div>
        </div>
        <div className="hex-col-4">
          <div className="hex-shadow hex-container-5">
            <div className="hex">
              <div className="hex__icon">
                <i className="_icon-venus"></i>
              </div>
              <div className="hex__title">{t("whatWeHeader5")}</div>
              <div className="hex__text">{t("whatWeText5")}</div>
            </div>
          </div>
          <div className="hex-shadow hex-container-6">
            <div className="hex">
              <div className="hex__icon">
                <i className="_icon-book-alt"></i>
              </div>
              <div className="hex__title">{t("whatWeHeader6")}</div>
              <div className="hex__text">{t("whatWeText6")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HexagonSection;
