import React, { useState } from "react";
import logo from "../../assets/logo.svg";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";

function Header() {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState();

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className=" header">
      <div className="container">
        <nav className="nav">
          <div className="header__logo">
            <Link to="/" className="logo">
              <img src={logo} alt="" />
            </Link>
          </div>
          <ul className={`nav-menu ${isOpen ? "active" : ""}`}>
            <NavLink to="/" activeClassName="active" exact>
              <li className="menu__link" onClick={() => setIsOpen(false)}>
                {t("home")}
              </li>
            </NavLink>
            <NavLink to="/technology" activeClassName="active">
              <li className="menu__link" onClick={() => setIsOpen(false)}>
                {t("technology")}
              </li>
            </NavLink>
            <NavLink to="/careers" activeClassName="active">
              <li className="menu__link" onClick={() => setIsOpen(false)}>
                {t("careers")}
              </li>
            </NavLink>
          </ul>
          <div
            className={`burger ${isOpen ? "active" : ""}`}
            onClick={handleIsOpen}
          >
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
