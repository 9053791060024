import React from "react";
import PositionText from "../positionText/PositionText";
import hexRightShadow from "../img/hex-right-shadow.svg";

function PositionItem({
  toggleActivePosition,
  activePosition,
  id,
  mainTechnologyIcon,
  secondaryTilte,
  title,
  qualifications,
  responsibilities,
  description
}) {
  return (
    <>
      <div className="clip-shadow">
        <div
          className={`position-item ${
            activePosition === id ? "position-item_active" : ""
          }`}
          onClick={() => toggleActivePosition(id)}
        >
          <div
            className={`${activePosition === id ? "clip_active" : ""}`}
          ></div>
          <div
            className={`position-item-img ${
              activePosition === id ? "position-item-img_active" : ""
            }`}
          >
            <img src={hexRightShadow} alt="" />
          </div>
          <div className={"position-icon"}>
            <img src={mainTechnologyIcon} alt="" />
          </div>
          <div className="position-text">
            <div
              className={`position-text__level ${
                activePosition === id ? "position-text_active" : ""
              }`}
            >
              {secondaryTilte}
            </div>
            <div className="position-text__name">{title}</div>
          </div>
        </div>
      </div>
      <div className="open-position-mobile-text">
        {activePosition === id ? (
          <PositionText
            title={title}
            description={description}
            qualifications={qualifications}
            responsibilities={responsibilities}
          />
        ) : null}
      </div>
    </>
  );
}

export default PositionItem;
