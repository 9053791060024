import React, { useState } from "react";
import ReactDom from "react-dom";
import { useTranslation } from "react-i18next";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

function ApplyingModal({ open, onClose }) {
  const [t] = useTranslation();
  const [labelValue, setLabelValue] = useState("");
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    errors: {
      firstName: false,
      lastName: false,
      email: false,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm(formData.errors)) {
      console.log("Valid Form");
    } else {
      console.log("Invalid Form");
    }
  };

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleFileUpload = (e) => {
    const uploadedValue = e.target.value;
    const uploadedFileName = uploadedValue.slice(
      uploadedValue.lastIndexOf("\\") + 1
    );
    setLabelValue(uploadedFileName);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let errors = formData.errors;
    switch (name) {
      case "firstName":
        errors.firstName = value.length < 5 ? true : false;
        break;
      case "lastName":
        errors.lastName = value.length < 5 ? true : false;
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      default:
        break;
    }

    setFormData({ ...formData, errors, [name]: value });
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className="overlay"></div>
      <div className="applying-modal">
        <div className="modal-header modal">
          <div className="modal__title">{t("modalTitle")}</div>
          <div className="modal__btn close" onClick={onClose}></div>
        </div>
        <div className="modal-form">
          <div className="inputs">
            <div className="first-name">
              <label
                className={formData.errors.firstName ? "error" : ""}
                htmlFor="firstName"
              >
                {t("firstName")}
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={handleChange}
              />
            </div>
            <div className="last-name">
              <label
                className={formData.errors.lastName ? "error" : ""}
                htmlFor="lastName"
              >
                {t("lastName")}
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={handleChange}
              />
            </div>
            <div className="e-mail">
              <label
                className={formData.errors.email ? "error" : ""}
                htmlFor="email"
              >
                {t("emailAddress")}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="upload">
          <div className="upload-text">{t("uploadCV")}</div>
          <div className="upload-actions">
            <div className="upload-label">
              {labelValue ? labelValue : t("noChoosenFile")}
            </div>
            <label htmlFor="cv" className="upload-btn">
              {t("chooseFile")}
              <input
                type="file"
                id="cv"
                accept="application/pdf"
                onChange={(e) => handleFileUpload(e)}
              />
            </label>
          </div>
        </div>
        <div className="modal-applying-btn" onClick={handleSubmit}>
          {t("applyNow")}
        </div>
      </div>
    </>,
    document.getElementById("applying-modal")
  );
}

export default ApplyingModal;
