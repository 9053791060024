import React from "react";
import gitVersion from "../../../_git_commit";

function GitVersion() {
  const gitInfo = gitVersion.logMessage
    ? gitVersion.logMessage
    : "version is not defined for windows dev";

  return <div className="git_version">v: {gitInfo}</div>;
}

export default GitVersion;
