import React from "react";
import View from "./components/view/View";
import { HashRouter } from "react-router-dom";
import ReactGA from "react-ga";
ReactGA.initialize("UA-196489563-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <HashRouter>
      <div className="App">
        <View />
      </div>
    </HashRouter>
  );
}

export default App;
