import reactJs from "../img/tech-icons/react.svg";
import nextJs from "../img/tech-icons/nextJs.svg";
import nodeJs from "../img/tech-icons/nodeJs.svg";
import sass from "../img/tech-icons/sass.svg";
import scss from "../img/tech-icons/scss.svg";
import css3 from "../img/tech-icons/css3.svg";
import python from "../img/tech-icons/python.svg";
import javascript from "../img/tech-icons/js.svg";
import bashScript from "../img/tech-icons/bashScript.svg";
import jenkins from "../img/tech-icons/jenkins.svg";
import selenium from "../img/tech-icons/selenium.svg";
import java from "../img/tech-icons/java.svg";
import saga from "../img/tech-icons/saga.svg";
import socket from "../img/tech-icons/socket.svg";
import docker from "../img/tech-icons/docker.svg";
import android from "../img/tech-icons/android.svg";
import ios from "../img/tech-icons/ios.svg";
import cpp from "../img/tech-icons/cpp.svg";
import socketsp from "../img/tech-icons/socketsp.svg";
import rabbitMq from "../img/tech-icons/rabbitMq.svg";
import mongoDb from "../img/tech-icons/mongoDb.svg";
import phaserJs from "../img/tech-icons/phaserJs.svg";
import typeScript from "../img/tech-icons/typeScript.svg";
import cSharp from "../img/tech-icons/cSharp.svg";
import mySql from "../img/tech-icons/mySql.svg";
///////////////////open vacancies//////////////
import cppIcon from "../img/vacancy-icons/cpp.svg";
import csharpIcon from "../img/vacancy-icons/csharp.svg";
import javaIcon from "../img/vacancy-icons/java.svg";
import javaScriptIcon from "../img/vacancy-icons/javaScript.svg";
import mongoIcon from "../img/vacancy-icons/mongo.svg";
import mySqlIcon from "../img/vacancy-icons/mySql.svg";
import nodeJsIcon from "../img/vacancy-icons/nodeJs.svg";
import phytonIcon from "../img/vacancy-icons/phyton.svg";
import rabbitMqIcon from "../img/vacancy-icons/rabbitMq.svg";
import reactJsIcon from "../img/vacancy-icons/reactJs.svg";
import seleniumIcon from "../img/vacancy-icons/selenium.svg";

export const technologyIcons = {
  reactJs,
  nextJs,
  nodeJs,
  sass,
  scss,
  css3,
  python,
  javascript,
  bashScript,
  jenkins,
  selenium,
  java,
  saga,
  socket,
  docker,
  android,
  ios,
  cpp,
  socketsp,
  rabbitMq,
  mongoDb,
  phaserJs,
  typeScript,
  cSharp,
  mySql,
};

export const openVacanciesIcons = {
  cppIcon,
  csharpIcon,
  javaIcon,
  javaScriptIcon,
  mongoIcon,
  mySqlIcon,
  nodeJsIcon,
  phytonIcon,
  rabbitMqIcon,
  seleniumIcon,
  reactJsIcon,
};
