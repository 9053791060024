import React from "react";
import facebook from "./img/icons/facebook.svg";
import linkedin from "./img/icons/linkedin.svg";
import logo from "../../assets/logo.svg";
import GitVersion from "./git_version/GitVersion";
import { useTranslation } from "react-i18next";

function Footer() {
  const [t] = useTranslation();

  return (
    <footer className="footer">
      <div className="angle"></div>
      <div className="container footer__container">
        <img src={logo} alt="" />
        <div className="contact-us">
          <div className="footer__content">
            <div className="contact-form">
              <span className="contact-title">{t("contactUs")}</span>
              <input type="text" placeholder="Name" />

              <input type="text" placeholder="Email" />
              <textarea
                className="message-area"
                name="message"
                id=""
                cols="30"
                rows="10"
                placeholder="Message"
              ></textarea>
              <div className="btn contact__btn">{t("sendMessage")}</div>
            </div>
            <div className="contact-info">
              <ul>
                <li className="list-item">
                  <span>
                    <i className="_icon-clipboard-notes"></i>
                  </span>
                  <div className="list-item__text">
                    <span className="info">{t("sendYourCV")}</span>
                    <span className="bold">{t("sendYourCVEmail")}</span>
                  </div>
                </li>
                <li className="list-item">
                  <span>
                    <i className="_icon-comments-alt"></i>
                  </span>
                  <div className="list-item__text">
                    <span className="info">{t("infoMail")}</span>
                    <span className="bold">{t("infoMailLink")}</span>
                  </div>
                </li>
                <li className="list-item">
                  <span>
                    <i className="_icon-map-marker-alt"></i>
                  </span>
                  <div className="list-item__text">
                    <span className="info">{t("address")}</span>
                    <span className="bold">{t("adressSt")}</span>
                  </div>
                </li>
                <li className="list-item">
                  <span>
                    <i className="_icon-phone"></i>
                  </span>
                  <div className="list-item__text">
                    <span className="info">{t("phone")}</span>
                    <span className="bold">{t("phoneNumber")}</span>
                  </div>
                </li>
              </ul>

              <div className="social-media-icons">
                <img src={facebook} alt="" />
                <img src={linkedin} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GitVersion />
    </footer>
  );
}

export default Footer;
