import React, { useState, useEffect } from "react";
import HexDesktop from "./HexDesktop";
import HexMobile from "./HexMobile";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../../customHooks/useWindowDimention";

function HexagonSection() {
  const [t] = useTranslation();
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (width < 1140) return setIsMobile(true);
    setIsMobile(false);
  }, [width]);

  return (
    <div className="hexagon-section">
      {isMobile ? <HexMobile /> : <HexDesktop />}
    </div>
  );
}

export default HexagonSection;
