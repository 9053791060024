import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import img01 from "./img/01.svg";
import img02 from "./img/02.svg";
import img03 from "./img/03.svg";
import leftArr from "./img/arrows/arr-left.svg";
import rightArr from "./img/arrows/arr-right.svg";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../../customHooks/useWindowDimention";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow({ className, style, onClick }) {
  return (
    <img
      alt=""
      src={rightArr}
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow({ className, style, onClick }) {
  return (
    <img
      alt=""
      src={leftArr}
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const CarouselComp = () => {
  const [t] = useTranslation();
  const { width } = useWindowDimensions();
  const [slidesToShow, setSlidesToShow] = useState(1);

  const settings = {
    slidesToShow,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    pauseOnHover: true,
    centerMode: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: () => <div className="hexagon"></div>,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
  };
  useEffect(() => {
    if (width <= 480) {
      setSlidesToShow(1.05);
      return;
    }
    if (width <= 667) {
      setSlidesToShow(1.4);
      return;
    }

    return setSlidesToShow(1);
  }, [width]);
  return (
    <div className="carousel">
      <div className="section-title carousel-header">{t("ourCoreValues")}</div>
      <div className="carousel__content">
        <Slider {...settings}>
          <div className="carousel-item">
            <div className="carousel-item__img">
              <img src={img01} alt="" />
            </div>
            <div className="item-text">
              <div className="item-text__title">{t("carouselOneTitle")}</div>
              <div className="item-text__list">
                <ul>
                  <li>{t("carouselOneLiOne")}</li>
                  <li>{t("carouselOneLiTwo")}</li>
                  <li>{t("carouselOneLiThree")}</li>
                  <li>{t("carouselOneLiFour")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="carousel-item__img">
              <img src={img02} alt="" />
            </div>
            <div className="item-text">
              <div className="item-text__title">{t("carouselTwoTitle")}</div>
              <div className="item-text__list">
                <ul>
                  <li>{t("carouselTwoLiOne")}</li>
                  <li>{t("carouselTwoLiTwo")}</li>
                  <li>{t("carouselTwoLiThree")}</li>
                  <li>{t("carouselTwoLiFour")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="carousel-item__img">
              <img src={img03} alt="" />
            </div>
            <div className="item-text">
              <div className="item-text__title">{t("carouselThreeTitle")}</div>
              <div className="item-text__list">
                <ul>
                  <li>{t("carouselThreeLiOne")}</li>
                  <li>{t("carouselThreeLiTwo")}</li>
                  <li>{t("carouselThreeLiThree")}</li>
                  <li>{t("carouselThreeLiFour")}</li>
                </ul>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default CarouselComp;
