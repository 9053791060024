import React, { useState, useEffect, useCallback } from "react";
import PositionItem from "./positionItem/PositionItem";
import PositionText from "./positionText/PositionText";
import { openVacanciesData } from "../../../../configs/openVacancies";
import useWindowDimensions from "../../../../customHooks/useWindowDimention";
import { useTranslation } from "react-i18next";

function OpenPosition() {
  const [t] = useTranslation();
  const [activePosition, setActivePosition] = useState(0);
  const { width } = useWindowDimensions();
  const toggleActivePosition = useCallback(
    (id) => {
      if (activePosition === id && width > 1024) return;
      activePosition !== id
        ? setActivePosition(id)
        : width >= 1024
        ? setActivePosition(1)
        : setActivePosition(0);
    },
    [activePosition]
  );

  useEffect(() => {
    if (width > 1024 && activePosition === 0) {
      setActivePosition(1);
    }
  }, [width, activePosition]);

  useEffect(() => {
    openVacanciesData.map((item, index) => {
      item.id = index + 1;
    });
  }, []);

  return (
    <div className="open-position-flex container-lg">
      <div className="open-position">
        <div className="section-title open-position__title">
          {t("nowHiring")}
        </div>
        <div className="open-position-items">
          {openVacanciesData.map((item, index) => {
            const {
              id = index + 1,
              title,
              description,
              secondaryTilte,
              qualifications,
              responsibilities,
              mainTechnologyIcon,
            } = item;
            return (
              <PositionItem
                key={index}
                toggleActivePosition={toggleActivePosition}
                activePosition={activePosition}
                id={id}
                mainTechnologyIcon={mainTechnologyIcon}
                secondaryTilte={secondaryTilte}
                title={title}
                qualifications={qualifications}
                responsibilities={responsibilities}
                description={description}
              />
            );
          })}
        </div>
      </div>

      <div className="open-position-desktop">
        {openVacanciesData.map((item, index) => {
          const {
            id,
            qualifications,
            responsibilities,
            description,
            title,
          } = item;
          if (id === activePosition) {
            return (
              <PositionText
                title={title}
                key={index}
                qualifications={qualifications}
                responsibilities={responsibilities}
                description={description}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default OpenPosition;
