import { openVacanciesIcons } from "./iconImports";
const {
  cppIcon,
  csharpIcon,
  javaIcon,
  javaScriptIcon,
  mongoIcon,
  mySqlIcon,
  nodeJsIcon,
  phytonIcon,
  rabbitMqIcon,
  seleniumIcon,
  reactJsIcon,
} = openVacanciesIcons;

export const openVacanciesData = [
  {
    title: "Javascript Front-End Developer",
    secondaryTilte: "Senior",
    mainTechnologyIcon: reactJsIcon,
    // description: "test description",
    qualifications: [
      "Highly skilled at front-end engineering using JavaScript, various JavaScript libraries and micro frameworks, HTML5, CSS3 to build large-scale applications in an enterprise environment;",
      "Expert understanding of HTTP/S, Web Caching, Cross Domain Integration, Web Application Security;",
      "At least 5 years of experience focused specifically on front-end development of software applications (NOT just websites);",
      "At least 4 years of experience of Javascript with different frameworks/librarie: we are mostly using ReactJS",
      "Experience in building mobile/responsive applications with heavy touch interactions and performance optimization;",
      "Deep understanding of OOP principles;",
      "Flexibility in learning and applying different programming languages and technologies;",
      "Good knowledge of English.",
    ],
    responsibilities: [],
  },
  {
    title: "C++ Software Engineer",
    secondaryTilte: "Senior",
    mainTechnologyIcon: cppIcon, //this is for the logo
    // description: "test description",
    qualifications: [
      "3+ years of professional experience with C++ programming for both Linux and Windows",
      "Good OOP knowledge and architectural thinking",
      "Good understanding of network protocols, interprocess communication, sockets",
      "Working knowledge with SQL databases",
      "Communication skills in English and Russian",
      "Higher education in Computer Science, Information systems, or similar discipline",
      "Willingness to participate in business trips",
    ],
    responsibilities: [
      "Design, build and maintain efficient and reliable C++ code",
      "Implement performance and quality modules",
      "Identify bottlenecks and bugs, and devise solutions to these problems",
      "Work closely with the team to help maintain code quality, organization, and automation & build new products",
    ],
  },
  {
    title: "UI/UX Designer",
    secondaryTilte: "Junior",
    mainTechnologyIcon: phytonIcon,
    qualifications: [
      "Proven work experience as a UI/UX Designer or similar role",
      "Portfolio of design projects",
      "Knowledge of design software",
      "Knowledge of wireframe tools",
      "Strong communication skills",
    ],
    responsibilities: [
      "Gather and evaluate user requirements in collaboration with product managers and engineers",
      "Design graphic user interface elements, like menus, tabs and widgets",
      "Find, create and adapt simple graphical assets",
      "Develop UI mockups and prototypes that clearly illustrate how sites function and look like",
      "Build prototypes with page navigations",
    ],
    // description: "test description",
  },
  {
    title: "Javascript Developer",
    secondaryTilte: "Junior",
    mainTechnologyIcon: javaScriptIcon,
    description:
      "We are looking for Junior Javascript developers, who will become the part of our team to work on world-class enterprise products. The Juniors will work with close supervision of professional software engineers involved in both BackEnd and FrontEnd development",
    qualifications: [
      "Experience with Web technologies HTML5, CSS3, JavaScript;",
      "Understanding of OOP basic concepts;",
      "Flexibility in learning and applying different programming languages and technologies;",
      "A positive attitude towards working within a team and being a team player;",
      "Good knowledge of English",
    ],
    responsibilities: [],
  },
  {
    title: "QA Software Engineer",
    secondaryTilte: "Junior",
    mainTechnologyIcon: seleniumIcon,
    qualifications: [
      "Ability to quickly understand the tested feature",
      "Ability to document and troubleshoot errors",
      "Analytical mind and problem-solving aptitude",
      "Flexibility in learning and applying different programming languages and technologies",
      "Good knowledge of English",
    ],
    responsibilities: [
      "Reviewing and analyzing system specifications;",
      "Reporting bugs and errors to the development team;",
      "Collaborating with multiple teams to ensure client’s needs are met;",
      "Having initiative and playing active role for suggesting improvements for the product.",
      "Learn and grow together with Senior engineers in a professional team",
    ],
  },
  {
	  title: "Office Manager",
	  secondaryTilte: "",
	  mainTechnologyIcon: javaIcon,
	  description: "We are looking for (some long list goes here though...). And we offer professional growth opportunities based on individual performance, opportunities to extend the activity in the Information Technology industry further if you have the right mix of skills and motivation",
    qualifications: [
	    "Strong administrative skills;",
	    "Excellent interpersonal and collaboration skills;",
	    "Passion for information technologies and innovations, eager to grow in this environment;",
	    "The ability to take the initiative;",
	    "Well organized, detail oriented, accurate and responsive;",
	    "Team player: collaborative, seeks input, accepts and incorporates feedback from others and freely offers assistance;",
	    "Good knowledge of English. You will be involved also in remote administration of our abroad teams;"
    ],
    responsibilities: [
    ],
  },
];
