import React from "react";
import WhatWeBuildItem from "./whatWeBuild-Item/WhatWeBuildItem";
import { technologyContent } from "../../../../configs/technologyPageContent";
import { useTranslation } from "react-i18next";

function WhatWeBuild() {
  const [t] = useTranslation();

  return (
    <div className="what-we">
      <div className="what-we__header section-title">{t("whatWeBuild")}</div>
      {technologyContent.map((item, index) => {
        const { description, technologies, img, slogan = "" } = item;
        return (
          <WhatWeBuildItem
            slogan={slogan}
            key={index}
            description={description}
            technologies={technologies}
            img={img}
          />
        );
      })}
    </div>
  );
}

export default WhatWeBuild;
