import React from "react";
import { useTranslation } from "react-i18next";

function WhatWeBuildItem({ description, technologies, img, slogan }) {
  const [t] = useTranslation();
  let iconAmount = technologies.length;

  return (
    <div className="what-we-build-item">
      <div className="section-white">
        <div className="section-white__text section-content">
          <div className="section-description above-description">
            {t(description)}
          </div>

          <div className="section-subtext above-subtext subtext">
            {t("technologies")}
          </div>
        </div>
      </div>
      <div className="section-inner">
        <div className="section-img">
          <img src={img} alt="" />
          <div className="slogan">{t(slogan)}</div>
        </div>
        <div className="section-content">
          <div className="section-content__text">
            <div className="section-description below-description">
              {t(description)}
            </div>
            <div className="section-subtext below-subtext ">
              {t("technologies")}
            </div>
          </div>
          <div
            className={`section-content__icons ${
              iconAmount % 3 === 0 ? "max-width-sm" : "max-width-lg"
            }`}
          >
            {technologies.map((item, index) => {
              const { itemText, itemIcon } = item;
              return (
                <div key={index} className="section-icon">
                  <div className="icons-text">{t(`${itemText}`)}</div>
                  <div className="icons-icon">
                    <img src={itemIcon} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeBuildItem;
