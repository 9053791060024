import React from "react";
import { useTranslation } from "react-i18next";

function HexDesktop() {
  const [t] = useTranslation();

  return (
    <div className="hex-desktop">
      <div className="wrapper">
        <div className="col-1 col">
          <div className="line1"></div>
          <div className="line2"></div>

          <div className="shadow hex-1">
            <div className="hex">
              <div className="hex-content">
                <div className="hex__icon">
                  <i className="_icon-heart"></i>
                </div>
                <div className="hex__title">{t("whatWeLiveTitle")}</div>
                <div className="hex__text"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 col">
          <div className="shadow hex-2">
            <div className="hex">
              <div className="hex-content">
                <div className="hex__icon">
                  <i className="_icon-brain"></i>
                </div>
                <div className="hex__title">{t("whatWeHeader1")}</div>
                <div className="hex__text">{t("whatWeText1")}</div>
              </div>
            </div>
          </div>
          <div className="shadow hex-3">
            <div className="hex">
              <div className="hex-content">
                <div className="hex__icon">
                  <i className="_icon-wifi"></i>
                </div>
                <div className="hex__title">{t("whatWeHeader2")}</div>
                <div className="hex__text">{t("whatWeText2")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 col">
          <div className="shadow hex-4">
            <div className="hex">
              <div className="hex-content">
                <div className="hex__icon">
                  <i className="_icon-trophy"></i>
                </div>
                <div className="hex__title">{t("whatWeHeader3")}</div>
                <div className="hex__text">{t("whatWeText3")}</div>
              </div>
            </div>
          </div>
          <div className="shadow hex-5">
            <div className="hex">
              <div className="hex-content">
                <div className="hex__icon">
                  <span className="_icon-user-check"></span>
                </div>
                <div className="hex__title">{t("whatWeHeader4")}</div>
                <div className="hex__text">{t("whatWeText4")}</div>
                <div className="hex__subtext">{t("whatWeSubText")}</div>
              </div>
            </div>
          </div>
          <div className="shadow-bg">
            <div className="hex"></div>
          </div>
        </div>
        <div className="col-4 col">
          <div className="shadow-bg">
            <div className="hex"></div>
          </div>
          <div className="shadow hex-6">
            <div className="hex">
              <div className="hex-content">
                <div className="hex__icon">
                  <i className="_icon-venus"></i>
                </div>
                <div className="hex__title">{t("whatWeHeader5")}</div>
                <div className="hex__text">{t("whatWeText5")}</div>
              </div>
            </div>
          </div>
          <div className="shadow hex-7">
            <div className="hex">
              <div className="hex-content">
                <div className="hex__icon">
                  <i className="_icon-book-alt"></i>
                </div>
                <div className="hex__title">{t("whatWeHeader6")}</div>
                <div className="hex__text">{t("whatWeText6")}</div>
              </div>
            </div>
          </div>

          <div className="bg-hex">
            <div className="shadow-bg">
              <div className="hex"></div>
            </div>
            <div className="shadow-bg">
              <div className="hex"></div>
            </div>
            <div className="shadow-bg">
              <div className="hex"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HexDesktop;
