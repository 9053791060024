import React, { useRef, useEffect } from "react";
import { getElem } from "../../../../helpers";

function PieChartComponent({ activeSegment, setActiveSegment }) {
  const path1 = useRef();
  const path2 = useRef();
  const path3 = useRef();
  const path4 = useRef();
  const pathArr = [path1, path2, path3, path4];

  const textStyle = {
    cursor: "pointer",
  };

  const changeTextColorOnHover = () => {
    getElem("seg-text").forEach((i) => i.setAttribute("fill", "#929292"));
    getElem(`${activeSegment}-text`).forEach((i) =>
      i.setAttribute("fill", "#ffffff")
    );
  };

  const mouseEnter = (e) => {
    pathArr.forEach((item) => {
      const currentId = item.current.id;
      const eventId = e.target ? e.target.id : e;
      if (currentId === eventId) {
        setActiveSegment(currentId);
        switch (currentId) {
          case "seg-1":
            item.current.setAttribute("fill", "#FB2D6C");
            document
              .getElementById("seg-1-line")
              .setAttribute("fill", "#FB2D6C");
            break;
          case "seg-2":
            item.current.setAttribute("fill", "#09FF97");
            document
              .getElementById("seg-2-line")
              .setAttribute("fill", "#09FF97");
            break;
          case "seg-3":
            item.current.setAttribute("fill", "#FFB12E");
            document
              .getElementById("seg-3-line")
              .setAttribute("fill", "#FFB12E");
            break;
          case "seg-4":
            item.current.setAttribute("fill", "#5E86FF");
            document
              .getElementById("seg-4-line")
              .setAttribute("fill", "#5E86FF");
            break;
          default:
        }
      } else {
        document
          .getElementById(currentId + "-line")
          .setAttribute("fill", "#979797");
        switch (currentId) {
          case "seg-1":
            item.current.setAttribute("fill", "#EDEDED");
            break;
          case "seg-2":
            item.current.setAttribute("fill", "#D8D8D8");
            break;
          case "seg-3":
            item.current.setAttribute("fill", "#BBBBBB");
            break;
          case "seg-4":
            item.current.setAttribute("fill", "#D8D8D8");
            break;
          default:
        }
      }
    });
  };

  useEffect(() => {
    changeTextColorOnHover();
  }, [activeSegment]);

  return (
    <div className="pie-chart">
      <svg
        width="481px"
        height="481px"
        viewBox="0 0 481 481"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <desc>Created with sketchtool.</desc>
        <defs>
          <circle id="path-1" cx="240.5" cy="240.5" r="240.5"></circle>
          <filter
            x="-2.2%"
            y="-2.2%"
            width="104.4%"
            height="104.4%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feGaussianBlur
              stdDeviation="10.5"
              in="SourceAlpha"
              result="shadowBlurInner1"
            ></feGaussianBlur>
            <feOffset
              dx="0"
              dy="0"
              in="shadowBlurInner1"
              result="shadowOffsetInner1"
            ></feOffset>
            <feComposite
              in="shadowOffsetInner1"
              in2="SourceAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
              result="shadowInnerInner1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.104449728 0"
              type="matrix"
              in="shadowInnerInner1"
            ></feColorMatrix>
          </filter>
          <circle id="path-3" cx="240" cy="240" r="204"></circle>
          <filter
            x="-6.2%"
            y="-6.2%"
            width="112.5%"
            height="112.5%"
            filterUnits="objectBoundingBox"
            id="filter-4"
          >
            <feOffset
              dx="0"
              dy="0"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="8.5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.117951766 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <linearGradient
          // x1="0%"
          // y1="88.1425151%"
          // x2="77.016952%"
          // y2="31.6028648%"
          // id="linearGradient-5"
          >
            <stop stopColor="#82ABFF" offset="0%"></stop>
            <stop stopColor="#4B72FF" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="Page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Charts" transform="translate(-418.000000, -122.000000)">
            <g id="Group-4" transform="translate(418.000000, 122.000000)">
              <g id="Oval" opacity="0.800548735">
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <g id="Oval">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-4)"
                  xlinkHref="#path-3"
                ></use>
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-3"
                ></use>
              </g>
              <g id="Group-10" transform="translate(69.000000, 57.000000)">
                <path
                  onMouseEnter={(e) => mouseEnter(e)}
                  ref={path1}
                  d="M46.9200009,133.169075 C65.614017,83.371783 113.63272,47.9175609 169.942519,47.8400009 L170.188986,114.999901 C170.168549,114.999884 170.14811,114.999875 170.127669,114.999875 C142.528206,114.999875 118.983775,132.361532 109.820079,156.759145 L46.9200009,133.169075 Z"
                  id="seg-1"
                  fill="#EDEDED"
                  style={{ cursor: "pointer" }}
                ></path>
                <path
                  onMouseEnter={(e) => mouseEnter(e)}
                  ref={path2}
                  d="M62.8990009,255.858541 C47.6225,234.368853 38.6400007,208.092693 38.6400007,179.718557 C38.6400007,163.416553 41.6050638,147.807076 47.0253136,133.400002 L109.862782,157.156874 C107.236148,164.178233 105.800002,171.78068 105.800002,179.718557 C105.800002,193.642985 110.219212,206.53525 117.731612,217.069335 L62.8990009,255.858541 Z"
                  id="seg-2"
                  fill="#D8D8D8"
                  style={{ cursor: "pointer" }}
                ></path>
                <path
                  onMouseEnter={(e) => mouseEnter(e)}
                  ref={path3}
                  d="M54.280001,260.620038 L117.612742,216.321737 C129.286461,232.718438 148.449778,243.412698 170.110874,243.412698 C192.624299,243.412698 212.439511,231.860276 223.951939,214.360004 L288.772351,256.454872 C263.476588,295.135644 219.778316,320.6927 170.110874,320.6927 C122.248689,320.6927 79.9296387,296.95972 54.280001,260.620038 Z"
                  id="seg-3"
                  fill="#BBBBBB"
                  style={{ cursor: "pointer" }}
                ></path>
                <path
                  onMouseEnter={(e) => mouseEnter(e)}
                  ref={path4}
                  d="M170.200003,26.6800088 C254.457402,26.7828229 322.729593,95.1184468 322.729593,179.399895 C322.729593,210.30353 313.550517,239.063276 297.770029,263.10147 L280.310222,251.159897 L224.239231,214.149347 C230.676265,204.124575 234.409592,192.198218 234.409592,179.399895 C234.409592,143.896249 205.679579,115.102857 170.200003,115.00017 L170.200003,26.6800005 Z"
                  id="seg-4"
                  fill="#5E86FF"
                  style={{ cursor: "pointer" }}
                ></path>
                <text
                  id="seg-4"
                  className="seg-4-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="22.0800004"
                  fontWeight="bold"
                  fill="#AFAFAF"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-4")}
                >
                  <tspan x="241" y="139">
                    35%
                  </tspan>
                </text>
                <text
                  id="Backend"
                  className="seg-4-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="14"
                  fontWeight="bold"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-4")}
                >
                  <tspan x="240" y="159">
                    Backend
                  </tspan>
                </text>
                <text
                  id="30%"
                  className="seg-3-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="22.0800004"
                  fontWeight="bold"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-3")}
                >
                  <tspan x="154" y="270">
                    30%
                  </tspan>
                </text>
                <text
                  id="17%"
                  className="seg-2-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="22.0800004"
                  fontWeight="bold"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-2")}
                >
                  <tspan x="48" y="186">
                    17%
                  </tspan>
                </text>
                <text
                  id="Devops"
                  className="seg-2-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="14"
                  fontWeight="bold"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-2")}
                >
                  <tspan x="48" y="206">
                    Devops
                  </tspan>
                </text>
                <text
                  id="Frontend"
                  className="seg-3-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="14"
                  fontWeight="bold"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-3")}
                >
                  <tspan x="154" y="290">
                    Frontend
                  </tspan>
                </text>
                <text
                  id="18%"
                  className="seg-1-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="22.0800004"
                  fontWeight="bold"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-1")}
                >
                  <tspan x="94" y="103">
                    18%
                  </tspan>
                </text>
                <text
                  id="QA"
                  className="seg-1-text seg-text"
                  fontFamily="Montserrat-Bold, Montserrat"
                  fontSize="14"
                  fontWeight="bold"
                  style={textStyle}
                  onMouseEnter={() => mouseEnter("seg-1")}
                >
                  <tspan x="94" y="123">
                    QA
                  </tspan>
                </text>
                <path
                  d="M215.740004,208.380004 L326.600006,281.980005"
                  id="Path-22"
                  stroke="#979797"
                  strokeWidth="0.920000017"
                ></path>
                <path
                  d="M125.580002,210.220004 L17.9689442,286.48284"
                  id="Path-23"
                  stroke="#979797"
                  strokeWidth="0.920000017"
                ></path>
                <path
                  d="M119.140002,160.540003 L1.38000003,116.380002"
                  id="Path-24"
                  stroke="#979797"
                  strokeWidth="0.920000017"
                ></path>
                <path
                  d="M169.644797,125.120002 L169.916212,0.354498996"
                  id="Path-25"
                  stroke="#979797"
                  strokeWidth="0.920000017"
                ></path>
                <path
                  d="M9.33787856,119.187839 C33.9681362,54.4124376 96.5994812,8.36488631 169.996981,8.28011738 L169.996981,9.20010957 C96.9803929,9.28490048 34.675952,55.1011884 10.1859695,119.546671 L9.33787856,119.187839 Z"
                  id="seg-1-line"
                  fill="#979797"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M37.8568704,272.031195 C19.789187,246.007829 9.20000017,214.400534 9.20000017,180.320003 C9.20000017,160.138311 12.9133432,140.823916 19.694062,123.022786 L20.5614042,123.331017 C13.8147923,141.035733 10.1200002,160.246385 10.1200002,180.320003 C10.1200002,214.196016 20.6426054,245.614343 38.5975761,271.484743 L37.8568704,272.031195 Z"
                  id="seg-2-line"
                  fill="#979797"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M170.126697,12.8800159 C170.151131,12.8800055 170.175566,12.8800002 170.200003,12.8800002 C262.674563,12.8800002 337.640006,87.8454431 337.640006,180.320003 C337.640006,213.969935 327.713763,245.301468 310.629642,271.546236 L309.860341,271.041474 C326.84919,244.941626 336.720006,213.783541 336.720006,180.320003 C336.720006,88.3535451 262.166461,13.8000003 170.200003,13.8000003 C170.175566,13.8000003 170.151131,13.8000055 170.126697,13.800016 L170.126697,12.8800148 Z"
                  id="seg-4-line"
                  fill="#979797"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M26.46182,280.30803 L27.211139,279.774035 C58.8007749,325.043359 111.27172,354.660006 170.660003,354.660006 C231.816638,354.660006 285.637858,323.253364 316.874805,275.688939 L317.633089,276.210456 C286.230063,324.015618 232.131162,355.580006 170.660003,355.580006 C110.962706,355.580006 58.2183261,325.811029 26.46182,280.30803 Z"
                  id="seg-3-line"
                  fill="#979797"
                  fillRule="nonzero"
                ></path>
                <circle
                  id="Oval"
                  stroke="#979797"
                  strokeWidth="0.920000017"
                  cx="170.200003"
                  cy="179.400003"
                  r="54.280001"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default PieChartComponent;
