import React from "react";
import applying from "./img/applying.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Applying() {
  const [t] = useTranslation();

  return (
    <div className="applying">
      <div className="applying__img">
        <img src={applying} alt="" />
      </div>
      <div className="applying__text">{t("applyingForAJob")}</div>
      <Link to="/applying-guide" className="applying__btn btn">
        {t("readMoreButton")}
      </Link>
    </div>
  );
}

export default Applying;
