import React from "react";
import CareersBanner from "../../banners/CareersBanner";
import Applying from "./applying/Applying";

import Carousel from "./carousel/Carousel";
import Perks from "./perks/Perks";
import OpenPosition from "./openPosition/OpenPosition";

function Careers() {
  return (
    <div style={{ overflow: "hidden" }}>
      <CareersBanner />
      <OpenPosition />
      <Applying />
      <Carousel />
      <Perks />
    </div>
  );
}

export default Careers;
