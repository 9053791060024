import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import dropdownIcon from "./img/arrDown.svg";

function DropDown({ title, content }) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpenDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (title === "qualifications") setIsOpen(true);
  }, [title]);

  const renderContent = () => {
    if (!content) return null;
    if (Array.isArray(content)) {
      return content.map((textItem, index) => {
        return <li key={index}>{textItem}</li>;
      });
    }
    return <p className="dropdown-description">{content}</p>;
  };

  return (
    <div className="dropdown">
      <div className="dropdown__header" onClick={toggleOpenDropdown}>
        <div className={`dropdown-icon ${isOpen ? "rotate" : ""}`}>
          <img src={dropdownIcon} alt="" />
        </div>
        <div className="dropdown-title">{t(title)}</div>
      </div>
      <ul className={`dropdown-list ${isOpen ? "show" : ""}`}>
        {renderContent()}
      </ul>
    </div>
  );
}

export default DropDown;
