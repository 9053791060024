import React, { useState } from "react";
import PieChartComponent from "../pieChart/PieChartComponent";
import { useTranslation } from "react-i18next";

const ChartTextComponent = ({ title, text, titleColor }) => {
  const chartTextItem = text.split(",");

  return (
    <div className="chart-text">
      <div className="chart-text__title" style={{ color: titleColor }}>
        {title}
      </div>
      <ul className="chart-text__body">
        {chartTextItem.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

function Chart() {
  const [activeSegment, setActiveSegment] = useState("seg-4");
  const [t] = useTranslation();

  const chartText = {
    sector1: {
      title: t("sector1Title"),
      text: t("sector1Text"),
    },
    sector2: {
      title: t("sector2Title"),
      text: t("sector2Text"),
    },
    sector3: {
      title: t("sector3Title"),
      text: t("sector3Text"),
    },
    sector4: {
      title: t("sector4Title"),
      text: t("sector4Text"),
    },
  };

  const renderChartText = () => {
    switch (activeSegment) {
      case "seg-1":
        return (
          <ChartTextComponent
            title={chartText.sector1.title}
            text={chartText.sector1.text}
            titleColor="#FB2D6C"
          />
        );
      case "seg-2":
        return (
          <ChartTextComponent
            title={chartText.sector2.title}
            text={chartText.sector2.text}
            titleColor="#09FF97"
          />
        );
      case "seg-3":
        return (
          <ChartTextComponent
            title={chartText.sector3.title}
            text={chartText.sector3.text}
            titleColor="#FFB12E"
          />
        );
      case "seg-4":
        return (
          <ChartTextComponent
            title={chartText.sector4.title}
            text={chartText.sector4.text}
            titleColor="#4C73FF"
          />
        );
      default:
    }
  };

  return (
    <div className="chart">
      <PieChartComponent
        activeSegment={activeSegment}
        setActiveSegment={setActiveSegment}
      />

      {renderChartText()}
    </div>
  );
}

export default Chart;
