import React from "react";
import img01 from "./img/01.png";
import img02 from "./img/02.png";
import img03 from "./img/03.png";
import img04 from "./img/04.png";
import img05 from "./img/05.png";
import img06 from "./img/06.png";
import img07 from "./img/07.png";
import img08 from "./img/08.png";
import { useTranslation } from "react-i18next";

function Perks() {
  const [t] = useTranslation();

  return (
    <div className="perks">
      <div className="section-title perks__title">{t("perks")}</div>
      <div className="perks-content">
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img01} alt="" />
          </div>
          <div className="perks-text">{t("perks1")}</div>
        </div>
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img02} alt="" />
          </div>
          <div className="perks-text">{t("perks2")}</div>
        </div>
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img03} alt="" />
          </div>
          <div className="perks-text">{t("perks3")}</div>
        </div>
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img04} alt="" />
          </div>
          <div className="perks-text">{t("perks4")}</div>
        </div>
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img05} alt="" />
          </div>
          <div className="perks-text">{t("perks5")}</div>
        </div>
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img06} alt="" />
          </div>
          <div className="perks-text">{t("perks6")}</div>
        </div>
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img07} alt="" />
          </div>
          <div className="perks-text">{t("perks7")}</div>
        </div>
        <div className="perks-content__item">
          <div className="perks-img">
            <img src={img08} alt="" />
          </div>
          <div className="perks-text">{t("perks8")}</div>
        </div>
      </div>
    </div>
  );
}

export default Perks;
