import React from "react";
import leftImg from "./img/hex-left.svg";
import rightImg from "./img/hex-right.svg";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const [t] = useTranslation();

  return (
    <div className="about-us">
      <div className="about-us-title section-title ">
        {t("aboutSectionTitle")}
      </div>
      <div className="container-1">
        <div className="left-img">
          <img src={leftImg} alt="" />
        </div>
        <div className="p1">{t("aboutSectionParagraph1")}</div>
      </div>
      <div className="container-2">
        <div className="right-img">
          <img src={rightImg} alt="" />
        </div>
        <div className="p2">{t("aboutSectionParagraph2")}</div>
      </div>
    </div>
  );
}

export default AboutUs;
