import React from "react";
import { technologyIcons } from "../../../../configs/iconImports";
import { useTranslation } from "react-i18next";

function TechStack() {
  const [t] = useTranslation();
  const {
    reactJs,
    nodeJs,
    python,
    javascript,
    selenium,
    java,
    cpp,
    rabbitMq,
    mongoDb,
    cSharp,
    mySql,
  } = technologyIcons;

  return (
    <div className="tech-stack">
      <div className="tech-icons">
        <div className="tech-stack__left">
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={nodeJs} alt="" />
            </div>
            <div className="tech-stack__text">{t("nodeJs")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={javascript} alt="" />
            </div>
            <div className="tech-stack__text">{t("javascript")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={reactJs} alt="" />
            </div>
            <div className="tech-stack__text">{t("ReactJS")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={reactJs} alt="" />
            </div>
            <div className="tech-stack__text">{t("reactNative")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={cpp} alt="" />
            </div>
            <div className="tech-stack__text">{t("cpp")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={cSharp} alt="" />
            </div>
            <div className="tech-stack__text">{t("cSharp")}</div>
          </div>
        </div>
        <div className="tech-stack__right">
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={java} alt="" />
            </div>
            <div className="tech-stack__text">{t("java")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={python} alt="" />
            </div>
            <div className="tech-stack__text">{t("python")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={mySql} alt="" />
            </div>
            <div className="tech-stack__text">{t("mySQL")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={mongoDb} alt="" />
            </div>
            <div className="tech-stack__text">{t("mongoDB")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={selenium} alt="" />
            </div>
            <div className="tech-stack__text">{t("selenium/preview")}</div>
          </div>
          <div className="tech-stack-item">
            <div className="tech-stack__img">
              <img src={rabbitMq} alt="" />
            </div>
            <div className="tech-stack__text">{t("rabbitMQ")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechStack;
