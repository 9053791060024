import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from "../header/Header";
import Home from "./home/Home";
import Technology from "./technology/Technology";
import Careers from "./careers/Careers";
import ApplyingGuide from "./careers/applyingGuide/ApplyingGuide";
import Footer from "../footer/Footer";
import ScrollToTop from "../ScrollToTop";

function View() {
  return (
    <div className="view">
      <Header />
      <ScrollToTop>
        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/technology" render={() => <Technology />} />
          <Route exact path="/careers" render={() => <Careers />} />
          <Route
            exact
            path="/applying-guide"
            render={() => <ApplyingGuide />}
          />
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default View;
