import React from "react";
import homeBg from "../view/home/img/home-bg.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HomeBanner() {
  const [t] = useTranslation();

  return (
    <div className="banner home-banner">
      <div className="blue-mask"></div>
      <div className="banner__img">
        <img src={homeBg} alt="" />
      </div>
      <div className="container">
        <div className="banner-content">
          <div className="section-title banner__title">
            <h1>{t("homeTitle")}</h1>
          </div>
          <div className="banner__subtext subtext">{t("homeSubtitle")}</div>
          <Link to="/careers" className="btn banner__btn">
            {t("joinUs")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
