import { technologyIcons } from "./iconImports";
import simpleImg from "../img/technologySupportImg/simple.png";
import monitorx3 from "../img/technologySupportImg/monitor.png";
import layers from "../img/technologySupportImg/layers.png";
import mobile from "../img/technologySupportImg/mobile.png";
import desktop from "../img/technologySupportImg/desktop.png";
import scalable from "../img/technologySupportImg/scalable.png";
import multi from "../img/technologySupportImg/multi.png";
import managment from "../img/technologySupportImg/managment.png";
import complex from "../img/technologySupportImg/complex.png";

const {
  reactJs,
  nextJs,
  nodeJs,
  sass,
  scss,
  css3,
  python,
  javascript,
  bashScript,
  jenkins,
  selenium,
  java,
  saga,
  socket,
  docker,
  android,
  ios,
  cpp,
  socketsp,
  rabbitMq,
  mongoDb,
  phaserJs,
  typeScript,
} = technologyIcons;

export const technologyContent = [
  {
    description: "hexSectionDescription1",
    slogan: "hexSection1Slogan",
    img: simpleImg,
    technologies: [
      {
        itemText: "reactJs",
        itemIcon: reactJs,
      },
      {
        itemText: "nextJs",
        itemIcon: nextJs,
      },
      {
        itemText: "nodeJs",
        itemIcon: nodeJs,
      },
      {
        itemText: "scss",
        itemIcon: scss,
      },
      {
        itemText: "sass",
        itemIcon: sass,
      },
      {
        itemText: "css3",
        itemIcon: css3,
      },
    ],
  },
  {
    description: "hexSectionDescription2",
    technologies: [
      {
        itemText: "python",
        itemIcon: python,
      },
      {
        itemText: "javascript",
        itemIcon: javascript,
      },
      {
        itemText: "bashScript",
        itemIcon: bashScript,
      },
      {
        itemText: "selenium/short",
        itemIcon: selenium,
      },
      {
        itemText: "javascript",
        itemIcon: javascript,
      },
      {
        itemText: "java",
        itemIcon: java,
      },
    ],
    img: monitorx3,
  },
  {
    description: "hexSectionDescription3",
    icon: [javascript, docker, python, bashScript],
    iconText: ["javascript", "docker", "python", "bashScript"],
    img: monitorx3,
    technologies: [
      {
        itemText: "javascript",
        itemIcon: javascript,
      },
      {
        itemText: "docker",
        itemIcon: docker,
      },
      {
        itemText: "python",
        itemIcon: python,
      },
      {
        itemText: "bashScript",
        itemIcon: bashScript,
      },
    ],
  },
  {
    description: "hexSectionDescription4",
    slogan: "hexSection4Slogan",
    technologies: [
      {
        itemText: "reactJs",
        itemIcon: reactJs,
      },
      {
        itemText: "saga",
        itemIcon: saga,
      },
      {
        itemText: "nextJs",
        itemIcon: nextJs,
      },
      {
        itemText: "nodeJs",
        itemIcon: nodeJs,
      },
      {
        itemText: "webSockets",
        itemIcon: socket,
      },
      {
        itemText: "css3",
        itemIcon: css3,
      },
      {
        itemText: "scss",
        itemIcon: scss,
      },
      {
        itemText: "sass",
        itemIcon: sass,
      },
    ],

    img: complex,
  },
  {
    description: "hexSectionDescription5",
    img: layers,
    technologies: [
      {
        itemText: "phaserJs",
        itemIcon: phaserJs,
      },
      {
        itemText: "reactJs",
        itemIcon: reactJs,
      },
      {
        itemText: "nextJs",
        itemIcon: nextJs,
      },
      {
        itemText: "nodeJs",
        itemIcon: nodeJs,
      },
      {
        itemText: "typeScript",
        itemIcon: typeScript,
      },
      {
        itemText: "webSockets",
        itemIcon: socket,
      },
      {
        itemText: "css3",
        itemIcon: css3,
      },
      {
        itemText: "scss",
        itemIcon: scss,
      },
      {
        itemText: "sass",
        itemIcon: sass,
      },
    ],
  },
  {
    description: "hexSectionDescription6",
    img: mobile,
    technologies: [
      {
        itemText: "React-native",
        itemIcon: reactJs,
      },
      {
        itemText: "android",
        itemIcon: android,
      },
      {
        itemText: "ios",
        itemIcon: ios,
      },
    ],
  },
  {
    description: "desktopApplications",
    img: desktop,
    technologies: [
      {
        itemText: "C++/QT",
        itemIcon: cpp,
      },
      {
        itemText: "React-native",
        itemIcon: reactJs,
      },
    ],
  },
  {
    description: "hexSectionDescription7",
    icon: [cpp, nodeJs, socketsp, rabbitMq],
    iconText: ["cpp", "nodeJs", "socketsP", "rabbitMQ"],
    img: scalable,
    technologies: [
      {
        itemText: "cpp",
        itemIcon: cpp,
      },
      {
        itemText: "nodeJs",
        itemIcon: nodeJs,
      },
      {
        itemText: "socketsP",
        itemIcon: socketsp,
      },
      {
        itemText: "rabbitMQ",
        itemIcon: rabbitMq,
      },
    ],
  },
  {
    description: "hexSectionDescription8",
    img: multi,
    technologies: [
      {
        itemText: "nodeJs",
        itemIcon: nodeJs,
      },
      {
        itemText: "mongoDB",
        itemIcon: mongoDb,
      },
      {
        itemText: "java",
        itemIcon: java,
      },
      {
        itemText: "rabbitMQ",
        itemIcon: rabbitMq,
      },
    ],
  },
  {
    description: "hexSectionDescription9",
    icon: [reactJs, nodeJs, socket, mongoDb],
    iconText: ["reactJs", "nodeJs", "webSockets", "mongoDB"],
    img: managment,
    technologies: [
      {
        itemText: "reactJs",
        itemIcon: reactJs,
      },
      {
        itemText: "nodeJs",
        itemIcon: nodeJs,
      },
      {
        itemText: "webSockets",
        itemIcon: socket,
      },
      {
        itemText: "mongoDB",
        itemIcon: mongoDb,
      },
    ],
  },
];
