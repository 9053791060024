import React from "react";
import careersImg from "../view/careers/img/careersImg.png";
import { useTranslation } from "react-i18next";

function CareersBanner() {
  const [t] = useTranslation();

  return (
    <div className="banner careers-banner">
      <div className="blue-mask"></div>
      <div className="banner__img">
        <img src={careersImg} alt="" />
      </div>
      <div className="container">
        <div className="banner-content">
          <div className="section-title banner__title">
            <h2>{t("technologyTitle")}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareersBanner;
