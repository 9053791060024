import React from "react";
import HomeBanner from "../../banners/HomeBanner";
import AboutUs from "./about-us/AboutUs";
import Chart from "./chart/Chart";
import HexagonSection from "./hexagonSection/HexagonSection";
import OurTeam from "./ourTeam/OurTeam";

function Home() {
  return (
    <div className="home">
      <HomeBanner />
      <AboutUs />
      <OurTeam />
      <Chart />
      <HexagonSection />
    </div>
  );
}

export default Home;
