import React from "react";
import TechnologyBanner from "../../banners/TechnologyBanner";
import TechStack from "./techStack/TechStack";
import WhatWeBuild from "./whatWeUsuallybuild/WhatWeBuild";

function Technology() {
  return (
    <div>
      <TechnologyBanner />
      <TechStack />
      <WhatWeBuild />
    </div>
  );
}

export default Technology;
