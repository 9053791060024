import React from "react";
import technologyBg from "../view/technology/img/technology-back.png";
import { useTranslation } from "react-i18next";

function TechnologyBanner() {
  const [t] = useTranslation();

  return (
    <div className="banner technology-banner">
      <div className="blue-mask"></div>
      <div className="banner__img">
        <img src={technologyBg} alt="" />
      </div>
      <div className="container">
        <div className="banner-content">
          <div className="section-title banner__title">
            <h1>{t("technologyHomeTitle")}</h1>
          </div>
          <div className="banner__subtext subtext">{t("technologyText")}</div>
        </div>
      </div>
    </div>
  );
}

export default TechnologyBanner;
