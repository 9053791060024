import React, { useState, useEffect } from "react";
import ApplyingModal from "../../applyingModal/ApplyingModal";
import { useTranslation } from "react-i18next";
import DropDown from "./dropdown/DropDown";

function PositionText({
  qualifications,
  responsibilities,
  description,
  title,
}) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="open-position-text">
      <div className="open-position-dropdowns">
        {/* {description && description.length ? (
          <DropDown title="description" content={description} />
        ) : (
          ""
        )} */}
        <div className="open-position-description">
          {description}
        </div>
        {qualifications && qualifications.length ? (
          <DropDown title="qualifications" content={qualifications} />
        ) : (
          ""
        )}
        {responsibilities && responsibilities.length ? (
          <DropDown title="responsibilities" content={responsibilities} />
        ) : (
          ""
        )}
      </div>

      <a href={`mailto:info@cloudsidetech.com?subject=${title}`}>
        <div className="btn apply-btn" onClick={() => setIsOpen(true)}>
          {t("applyNow")}
        </div>
      </a>

      {/* <ApplyingModal open={isOpen} onClose={() => setIsOpen(false)} /> */}
    </div>
  );
}

export default PositionText;
